<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10120')}}
							</h2>
							<p class="inner__text">
								{{t('10121')}}
							</p>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<!-- 휴대폰 번호 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10037')}}</h3>
									<div class="frms__wrap">
										<input type="tel" class="ipt-frm" :placeholder="t('10827')" :value="phoneNumber" @input="updatePhoneNumber($event)" @keyup="checkNumber($event)" maxlength="13" :disabled="isSended"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-if="phoneNumber.length > 0" @click="reset()" :disabled="isSended">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
											<button type="button" class="btn btn-medium btn-secondary" :class="{active: isPhoneNumber && (!isSended || phoneValidateTime === 0)}" 
											:disabled="!(isPhoneNumber && (!isSended || phoneValidateTime === 0))" @click="onClickSendPhoneNumber">{{t('10122')}}</button>
											<!-- <button type="button" class="btn btn-medium btn-secondary" @click="fnPopup">{{t('10123')}}</button> -->
										</div>
									</div>
								</div>						
								<!-- 인증 번호 -->
								<div class="frms__item" v-if="isSended">
									<h3 class="frms__title">{{t('10124')}}</h3>
									<div class="frms__wrap" :class="{failed: isFailed}">
										<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm" :placeholder="t('10779')" :value="verifyCode" @input="event => verifyCode = event.target.value"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<!-- 남은 시간 -->
											<span class="frms__time eng">
												{{String(Math.trunc(phoneValidateTime / 60)).padStart(2, "0")}}:{{String(phoneValidateTime % 60).padStart(2, "0")}}
											</span>
											<button type="button" class="btn btn-medium btn-secondary active" @click="reSend">{{t('10125')}}</button>
										</div>
									</div>
									<div class="frms__msg" v-if="isFailed">
										{{t('10126')}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	<div >
		<div class="btns btns--centered" >
			<button type="button" class="btn" :class="verifyCode.toString().length > 0 ? 'btn-confirm' : 'btn-disabled'" :disabled="verifyCode.toString().length === 0" @click="verify()">본인인증 완료</button>
		</div>
	</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import usePhoneValidate from '@/composables/usePhoneValidate';
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const phoneNumber = computed(() => store.state.mmb01.phoneNumber);
		const email = computed(() => store.state.mmb01.email);
		const id = computed(() => store.state.mmb01.id);
		const verifyCode = ref('');
		const isPhoneNumber = ref(false);
		const sessionId = computed(() => store.state.sessionId);
		const isSended = ref(false);
		const isFailed = ref(false); 
		const openErrorAlert = ref(false)

		const userData = computed(() => store.state.userData);
		const {
			phoneValidateTime,
			onClickSendPhoneNumber,
			serverPhoneValidateNumber
		} = userData.value.sns_ci === undefined ? usePhoneValidate("01") : usePhoneValidate("04") ;
		const snsPhoneNumber = computed(() => store.state.mmb01.snsPhoneNumber);
		
		const updatePhoneNumber = (e) => {
			store.commit('mmb01/SET_PHONE_NUMBER', {phoneNumber: e.target.value});
		};

		let ipAddr = "";
		const getIp = async () => {
			ipAddr = await fetch('https://api64.ipify.org').then(res=>res.text());
		}

		function fnPopup(){
			window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
			document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
			document.form_chk.target = "popupChk";
			document.form_chk.submit();

			//window.open("about:blank","_self").close();
		}

		const checkNumber = () => {
			// event = (event) ? event : window.event;
			// const charCode = (event.which) ? event.which : event.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			// 	event.preventDefault();
			// } else {
			// 	return true;
			// }
			const number = phoneNumber.value.replace(/[^0-9]/g, "");
			let phone = "";

			if(number.length < 4) { 
				phone = number; 
			}
			else if(number.length < 7) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3); 
			} 
			else if(number.length < 11) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3, 3); 
				phone += "-"; phone += number.substr(6); 
			} 
			else { 
				phone += number.substr(0, 3); 
				phone += "-"; 
				phone += number.substr(3, 4); 
				phone += "-"; 
				phone += number.substr(7); 
			}
			store.commit("mmb01/SET_PHONE_NUMBER", {phoneNumber: phone});

			const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
			if(regPhone.test(phone)) {
				isPhoneNumber.value = true;
			} else {
				isPhoneNumber.value = false;
			}
		}

		const reset = () => {
			store.commit("mmb01/SET_PHONE_NUMBER", {phoneNumber: ""});
			isPhoneNumber.value = false;
		}

		const verify = async () => {
				
			//console.log(verifyCode.value);
			if(serverPhoneValidateNumber.value !== verifyCode.value.toString()) {
				//alert("인증 번호가 일치하지 않습니다. 다시 확인해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "인증 번호가 일치하지 않습니다. 다시 확인해주세요."});
				openErrorAlert.value = true;

				isFailed.value = true;
				return;
			}
			if(phoneValidateTime.value === 0){
				//alert("입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."});
				openErrorAlert.value = true;

				return;
			}
			if (userData.value.ios_email){
				if(ipAddr.length === 0){
					getIp();
				}
      			const ip = await fetch('https://api64.ipify.org').then(res=>res.text());      
				store.dispatch("mmb01/fetchMmb01", {
					mem_id: userData.value.ios_email,
					sessionid: sessionId.value,
          			ip_addr: ip,
					sns_join_flag: "03",
					sns_ci:userData.value.sns_ci,
					hp_no: phoneNumber.value,
					fcm_token:userData.value.fcmToken,
				}).then(x => {
          			if(x.Res_cd === "0000" && x.Res_data.length > 0){
          			  store.commit("SET_USERDATA", {userData: x.Res_data[0]})
          			  router.replace("/");
          			}
          			else if(x.Res_cd === "9999" || x.Res_cd === "9998"){
          			  //alert(x.Res_msg)
					  store.commit("app/SET_RESMESSAGE", {res_message: null});
					  store.commit("app/SET_RESMESSAGE", {res_message: x.Res_msg});
					  openErrorAlert.value = true;
            		  store.commit("SET_USERDATA", {userData: {}});
          			  store.commit("SET_USERDATA", {userData: x.Res_data[0]})
          			  router.replace("/");
          			}
          			else if(x.Res_cd === "9990"){
						onClickSendPhoneNumber(phoneNumber.value);
            			store.commit("SET_USERDATA", {userData:{}})
						router.replace("/join/step3");
          			}
					else if(x.Res_cd === "9991"){
          			  store.commit("SET_USERDATA", {userData: {
							...userData.value,
							hp_no:phoneNumber.value,
							snsJoinFlag: '03',
						}
						})
          			  router.replace("/mypage/snslink");
          			}
				});
			}
			if (!userData.value.ios_email){
				router.push("/join/step3");
			}
		}

		onMounted(async ()=>{
			if(snsPhoneNumber.value !== null) {
				store.commit("mmb01/SET_PHONE_NUMBER", {phoneNumber: snsPhoneNumber.value});
				isPhoneNumber.value = true;
				try {
					// //console.log(phoneNumber.value);
					// await onClickSendPhoneNumber(phoneNumber.value);
					isSended.value = true;
					router.replace("/join/step3");
				}catch(e) {
					isSended.value = false;
					console.error(e);
				}
			}
		});
		const { t }= useI18n() //번역필수 모듈

		return {
			phoneNumber,
			email,
			verifyCode,
			isPhoneNumber,
			fnPopup,
			checkNumber,
			id,
			userData,
			reset,
			verify,
			updatePhoneNumber,
			phoneValidateTime,
			onClickSendPhoneNumber: async () => {
				try {
					await onClickSendPhoneNumber(phoneNumber.value);
					isSended.value = true;
					}catch(e) {
					 isSended.value = false;
					 console.error(e);
				}
			},
			isFailed,
			isSended,
			goBack: () => {
				router.back();
			},
			reSend: () => {
				if(phoneValidateTime.value > 0){
					//alert("인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."});
					openErrorAlert.value = true;
				}else {
					//alert("인증번호를 재발송 하였습니다.");				
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다."});
					openErrorAlert.value = true;
				}
				onClickSendPhoneNumber(phoneNumber.value);
			},
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,
			i18n,
		}
	},
}
</script>